import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { API_RESPONSE, Language, RequestStatusColors, TranslatorSkill, User } from '../../interfaces';
import { HttpService } from '../../services/http-service';
import { NavigationService } from '../../services/navigation.service';
import { ToastService } from '../../services/toast-service';
import { TranslationService } from '../../services/translation-service';
import { ChartOptions, ChartData } from 'chart.js';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '../../services/confirmation-dialog-service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Output() drawer: EventEmitter<any> = new EventEmitter();
  userData: User;
  languages: Language[] = [];
  skills: TranslatorSkill[] = [];

  excelDownloadLink: string;

  // Pie Chart
  pieChartOptions: ChartOptions<'pie'> = {
    responsive: true,
  };
  pieChartData: ChartData<'bar'> = { labels: [], datasets: [] };
  allRequestsCount = 0;
  constructor(
    public translationService: TranslationService,
    public httpService: HttpService,
    private toastService: ToastService,
    private navigationService: NavigationService,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getUserProfile();
    if (this.userData.role === 'ADMIN') {
      await this.getRequestCount();
    }
  }

  /**
   * fill the pie chart with statistic data about requests count
   */
  async getRequestCount() {
    const getRequestCountresp = await this.httpService.get('getRequestCount') as API_RESPONSE;
    if (getRequestCountresp?.data?.statuses?.length) {
      this.allRequestsCount = 0;
      getRequestCountresp.data.counts.map(count => this.allRequestsCount += count);
      const translatedLabels = getRequestCountresp.data.statuses.map(status => this.translationService.translateMessage(status));
      const labelColors = getRequestCountresp.data.statuses.map(status => RequestStatusColors[status]);
      this.pieChartData = {
        labels: translatedLabels,
        datasets: [
          {
            // label: "Title label",
            data: getRequestCountresp.data.counts,
            backgroundColor: labelColors,
            hoverBackgroundColor: labelColors,
          }
        ]
      }
    }
  }

  async getUserProfile() {
    const resp = await this.httpService.get('getUserProfile') as API_RESPONSE;
    if (!resp.success) {
      this.toastService.showErrorSnack(resp.message);
    } else {
      this.userData = resp.data as User;
      const skillset = this.userData.skills.join('_');
      if (skillset.includes(Language.RUSSIAN)) {
        this.languages.push(Language.RUSSIAN);
      }
      if (skillset.includes(Language.UKRAINIAN)) {
        this.languages.push(Language.UKRAINIAN);
      }
      if (skillset.includes(TranslatorSkill.TRANSLATE)) {
        this.skills.push(TranslatorSkill.TRANSLATE);
      }
      if (skillset.includes(TranslatorSkill.INTERPRET)) {
        this.skills.push(TranslatorSkill.INTERPRET);
      }

      this.userData.personalFilter = this.userData.personalFilter || { hideMedicalTranslation: false };
      console.log('%cLOGGER', 'background: blue', 'this.languages', this.languages);
      console.log('%cLOGGER', 'background: cyan', 'this.skills', this.skills);
    }
  }

  async updateUserProfile() {
    // create skillset
    const skillset = [];
    this.languages.forEach(lang => {
      this.skills.forEach(skill => {
        skillset.push(`${lang}_${skill}`);
      });
    });
    this.userData.skills = skillset;
    const resp = await this.httpService.post('updateUserProfile', { userData: this.userData }) as API_RESPONSE;
    if (!resp.success) {
      this.toastService.showErrorSnack(resp.message);
    } else {
      this.toastService.showSuccessSnack(resp.message);
      this.navigationService.refreshRequestList.next(true);
    }
  }

  // Build URL for downloading Excel file and download it
  async downloadRequestStatistic() {
    const resultedUrl = this.httpService.buildUrl('getRequestsAsExcel');
    window.open(resultedUrl);
  }

  async downloadTranslatorsStatistic() {
    const resultedUrl = this.httpService.buildUrl('getTranslatorsAsExcel');
    window.open(resultedUrl);
  }
  async downloadRequestCountStatistic() {
    const resultedUrl = this.httpService.buildUrl('getRequestCountAsExcel');
    window.open(resultedUrl);
  }

  async deleteUserAccount() {
    const confirmed = await this.confirmationDialogService.openConfirmationDialog({ message: 'CONFIRMATION_MESSAGE_DELETE_USER' });
    // call a confirmation before Assigning Request
    if (confirmed) {
      // delete user account && his open (WAITING) requests
      const resp = await this.httpService.get('deleteUserAccount') as API_RESPONSE;
      if (!resp.success) {
        this.toastService.showErrorSnack(resp.message);
      } else {
        this.toastService.showSuccessSnack(resp.message);
        // logout
        localStorage.removeItem('sessionid');
        this.httpService.sessionid = '';
        this.router.navigate(['login']);
      }
    }


  }


  closeDrawer() {
    this.drawer.emit();
  }
}

// helper function to parse the blob to an arraybuffer
function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}
