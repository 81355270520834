export interface API_RESPONSE {
  success: boolean;
  data?: any;
  message?: string;
  internalError?: any; // Error from internal actions mongoose, fs, etc.
}

export interface User {
  _id?: any;
  role: Role;
  email: string;
  password?: string;
  skills?: Skill[];
  name: string;
  telegram?: string;
  phone?: string;
  instagram?: string;
  runningSession?: string;
  zipCode?: number;
  city?: string;
  location?: GeoLocation;
  personalFilter?: PersonalFilter;
}

export interface PersonalFilter {
  hideMedicalTranslation?: boolean;
}
export interface TranslationRequest {
  _id?: any;
  Nr: number;
  requestDate?: Date;
  requestedSkill: Skill;
  status: RequestStatus;
  // Translate Details
  textType?: string;
  textAmountOfPages?: number;
  handwritten?: boolean;
  medicalTranslation?: boolean;
  // Interpret Details
  appointmentDateTime?: Date;
  appointmentDuration?: number;
  appointmentTheme?: string;
  appointmentAddress?: string;
  phoneInterpretation?: boolean;
  flexibleDate?: boolean;
  location?: GeoLocation;
  distance?: number; // localProperty

  description: string;
  languageFrom: Language;
  languageTo: Language;
  requestor?: User;
  translator?: User;
  createdAt?: Date;
  assignedDate?: Date;
  ///// LOCAL PROPERTIES
  // isOfficial?: boolean; // REMOVE: deprecated
  requestType?: string;
  comment?: string;
}

export interface GeoLocation {
  type?: string; //'Polygon' | 'Point';
  coordinates?: number[]; // Array of arrays of arrays of numbers
}

export interface ConfirmationDialogData {
  title?: string;
  message?: string;
  btnOkText?: string;
  btnCancelText?: string;
}


export enum Role {
  REQUESTOR = 'REQUESTOR',
  TRANSLATOR = 'TRANSLATOR',
  ADMIN = 'ADMIN',
}

export enum FilterType {
  OWN = 'OWN',
  MATCHING = 'MATCHING',
}

export enum Skill {
  RUSSIAN_TRANSLATE = 'RUSSIAN_TRANSLATE',
  RUSSIAN_INTERPRET = 'RUSSIAN_INTERPRET',
  UKRAINIAN_TRANSLATE = 'UKRAINIAN_TRANSLATE',
  UKRAINIAN_INTERPRET = 'UKRAINIAN_INTERPRET',
  UKRAINIAN_OR_RUSSIAN_INTERPRET = 'UKRAINIAN_OR_RUSSIAN_INTERPRET', // matches all Ukrainian and Russian interprets
  // RUSSIAN_TO_GERMAN_TRANSLATE = 'RUSSIAN_TRANSLATE',
  // RUSSIAN_TO_GERMAN_INTERPRET = 'RUSSIAN_INTERPRET',
  // UKRAINIAN_TO_GERMAN_TRANSLATE = 'UKRAINIAN_TRANSLATE',
  // UKRAINIAN_TO_GERMAN_INTERPRET = 'UKRAINIAN_INTERPRET',
  // UKRAINIAN_OR_RUSSIAN_TO_GERMAN_INTERPRET = 'UKRAINIAN_OR_RUSSIAN_INTERPRET', // matches all Ukrainian and Russian interprets
  // GERMAN_TO_RUSSIAN_TRANSLATE = 'RUSSIAN_TRANSLATE',
  // GERMAN_TO_RUSSIAN_INTERPRET = 'RUSSIAN_INTERPRET',
  // GERMAN_TO_UKRAINIAN_TRANSLATE = 'UKRAINIAN_TRANSLATE',
  // GERMAN_TO_UKRAINIAN_INTERPRET = 'UKRAINIAN_INTERPRET',
  // GERMAN_TO_UKRAINIAN_OR_RUSSIAN_INTERPRET = 'UKRAINIAN_OR_RUSSIAN_INTERPRET', // matches all Ukrainian and Russian interprets
  // RUSSIAN_TRANSLATE_OFFICIAL = 'RUSSIAN_TRANSLATE_OFFICIAL', // UNUSED: deprecated
  // RUSSIAN_INTERPRET_OFFICIAL = 'RUSSIAN_INTERPRET_OFFICIAL', // UNUSED: deprecated
  // UKRAINIAN_TRANSLATE_OFFICIAL = 'UKRAINIAN_TRANSLATE_OFFICIAL', // UNUSED: deprecated
  // UKRAINIAN_INTERPRET_OFFICIAL = 'UKRAINIAN_INTERPRET_OFFICIAL', // UNUSED: deprecated
}

export enum RequestStatus {
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  CANCELED = 'CANCELED', // UNUSED: for now mb we gonna need later, so requestor can cancel request
}

export enum RequestStatusColors {
  WAITING = '#ffff6d', //yellow
  IN_PROGRESS = '#ffaf1b', //orange
  DONE = '#00bb6d', // green
  CLOSED = '#05a1c6', // blue
  DELETED = '#ff5c5c', //red
  CANCELED = 'CANCELED', // UNUSED: for now mb we gonna need later, so requestor can cancel request
}

export const statusOrder = {
  WAITING: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  CLOSED: 3,
  DELETED: 4,
  // CANCELED: 5,
}

export enum Language {
  RUSSIAN = 'RUSSIAN',
  UKRAINIAN = 'UKRAINIAN',
  GERMAN = 'GERMAN',
  ENGLISH = 'ENGLISH',
  UKRAINIAN_OR_RUSSIAN = 'UKRAINIAN_OR_RUSSIAN'
}
export enum TranslatorSkill {
  TRANSLATE = 'TRANSLATE',
  INTERPRET = 'INTERPRET'
}



